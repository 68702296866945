import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import '@fontsource/roboto/700.css';

export default function Footer() {
    return (
        <Box sx={{ bgcolor: '#285c97', p: 2 }} component="footer">
            <Typography
                sx={{
                    fontSize: {
                        xs: '0.9rem',
                        sm: '1.1rem',
                        md: '1.1rem',
                        lg: '1.1rem',
                        xl: '1.1rem',
                    },
                }}
                align="center"
                color="#e2bb79"
            >
                Obsah stránky &nbsp;
                <Link color="inherit" href="https://www.vyrobapruzin.sk">
                    www.vyrobapruzin.sk
                </Link>{' '}
                &nbsp; je chránený autorským zákonom.
                <br />
                Akékoľvek šírenie alebo iné nakladenie s obsahom bez súhlasu prevádzkovateľa je nezákonné.
            </Typography>
            <Typography variant="body2" sx={{
                fontSize: {
                    xs: '0.9rem',
                    sm: '1.1rem',
                    md: '1.1rem',
                    lg: '1.1rem',
                    xl: '1.1rem',
                },
            }} color="#e2bb79" align="center">

                {'Copyright © '}
                {new Date().getFullYear()}
                {'.'}
            </Typography>
        </Box>)
};