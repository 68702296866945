import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { ListItemAvatar, Avatar } from '@mui/material';
import '@fontsource/roboto/700.css';


export default function Products() {
    return (
        <Box>
            <Grid container spacing={1}>
                <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                    <List sx={{
                        width: '100%', bgcolor: 'background.paper', paddingLeft: 5,
                        paddingTop: {
                            xs: 3,
                            sm: 6,
                            md: 7,
                            lg: 10,
                            xl: 10,
                        }
                    }}>
                        <ListItem sx={{ paddingTop: 2 }}>
                            <ListItemAvatar>
                                <Avatar
                                    alt="logo"
                                    src={require('../assets/pruzina.png')}
                                    sx={{ height: '70px', width: '70px' }}
                                />
                            </ListItemAvatar>
                            <Typography variant="h5" color="text.primary" sx={{ paddingLeft: 3, paddingTop: 2 }} paragraph>
                                Tlačné pružiny
                            </Typography>
                        </ListItem>

                        <ListItem sx={{ paddingTop: 2 }}>
                            <ListItemAvatar>
                                <Avatar
                                    alt="logo"
                                    src={require('../assets/pruzina.png')}
                                    sx={{ height: '70px', width: '70px' }}
                                />
                            </ListItemAvatar>
                            <Typography variant="h5" color="text.primary" sx={{ paddingLeft: 3, paddingTop: 2 }} paragraph>
                                Ťažné pružiny
                            </Typography>
                        </ListItem>

                        <ListItem sx={{ paddingTop: 2 }}>
                            <ListItemAvatar>
                                <Avatar
                                    alt="logo"
                                    src={require('../assets/pruzina.png')}
                                    sx={{ height: '70px', width: '70px' }}
                                />
                            </ListItemAvatar>
                            <Typography variant="h5" color="text.primary" sx={{ paddingLeft: 3, paddingTop: 2 }} paragraph>
                                Skrutné pružiny
                            </Typography>
                        </ListItem>
                    </List>
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                    <List sx={{
                        width: '100%', bgcolor: 'background.paper',
                        paddingLeft: {
                            xs: 5,
                            sm: 8,
                            md: 8,
                            lg: 8,
                            xl: 8,
                        },
                        paddingTop: {
                            xs: 3,
                            sm: 6,
                            md: 7,
                            lg: 10,
                            xl: 10,
                        }
                    }}>
                        <ListItem sx={{ paddingTop: 2 }}>
                            <ListItemAvatar>
                                <Avatar
                                    alt="logo"
                                    src={require('../assets/pruzina.png')}
                                    sx={{ height: '70px', width: '70px' }}
                                />
                            </ListItemAvatar>
                            <Typography variant="h5" color="text.primary" sx={{ paddingLeft: 3, paddingTop: 2 }} paragraph>
                                Tvarové pružiny
                            </Typography>
                        </ListItem>

                        <ListItem sx={{ paddingTop: 2 }}>
                            <ListItemAvatar>
                                <Avatar
                                    alt="logo"
                                    src={require('../assets/pruzina.png')}
                                    sx={{ height: '70px', width: '70px' }}
                                />
                            </ListItemAvatar>
                            <Typography variant="h5" color="text.primary" sx={{ paddingLeft: 3, paddingTop: 2 }} paragraph>
                                Krúžky
                            </Typography>
                        </ListItem>

                        <ListItem sx={{ paddingTop: 2 }}>
                            <ListItemAvatar>
                                <Avatar
                                    alt="logo"
                                    src={require('../assets/pruzina.png')}
                                    sx={{ height: '70px', width: '70px' }}
                                />
                            </ListItemAvatar>

                            <Typography variant="h5" color="text.primary" sx={{ paddingLeft: 3, paddingTop: 2 }} paragraph>
                                Závlačky
                            </Typography>
                        </ListItem>
                    </List>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <Box sx={{ bgcolor: 'background.paper' }}>
                        <Container >
                            <Box
                                component="img"
                                sx={{
                                    height: {
                                        xs: 320,
                                        sm: 430,
                                        md: 430,
                                        lg: 430,
                                        xl: 450
                                    },
                                    width: '100%',
                                }}
                                src={require('../assets/products.png')}
                            />
                        </Container>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}