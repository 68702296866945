import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

export default function TitleText() {
    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                <Box
                    component="img"
                    sx={{
                        height: {
                            xs: 250,
                            sm: 430,
                            md: 430,
                            lg: 430,
                            xl: 450
                        },
                        width: {
                            xs: '100%',
                            sm: '92%',
                            md: '92%',
                            lg: '92%',
                            xl: '92%'
                        },
                        marginLeft: '0.5%'
                    }}
                    src={require('../assets/machine.jpeg')}
                />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                <Box sx={{
                    bgcolor: 'background.paper',
                    paddingTop: {
                        xs: 2,
                        sm: 3,
                        md: 3,
                        lg: 4,
                        xl: 4,
                    }
                }}>
                    <Typography component="h1"
                        sx={{
                            fontSize: {
                                xs: '2.3rem',
                                sm: '2.8rem',
                                md: '3rem',
                                lg: '3rem',
                                xl: '3rem',
                            }
                        }} align="center" color="#285c97" gutterBottom>
                        Výroba pružín
                    </Typography>
                    <Typography variant="h5"
                        sx={{
                            fontSize: {
                                xs: '1.3rem',
                                sm: '1.5rem',
                                md: '1.7rem',
                                lg: '2rem',
                                xl: '2rem',
                            }
                        }}
                        align="center" color="text.primary" paragraph>
                        Zákazková a sériová výroba.<br />
                        Pri výrobe používame patentovaný pružinový drôt EN 10270-1<br />
                        od priemeru drôtu 0.2 do 5 mm.<br />
                        Zabezpečujeme výrobu ľubovoľného množstvá, už od 1 ks.
                    </Typography>
                </Box>
            </Grid>
        </Grid>
    )
}