import ReactDOM from 'react-dom/client';
import './index.css';
import Footer from './components/Footer.js'
import Header from './components/Header.js'
import TitleText from './components/TitleText.js'
import Products from './components/Products.js'
import Contact from './components/Contact.js'
import Box from '@mui/material/Box';
const root = ReactDOM.createRoot(document.getElementById('root'));

export default function Album() {
  return (
    <Box>
      <Header />
        <TitleText />
        <Products />
        <Contact />
      <Footer />
    </Box>
  );
}
root.render(Album());