import Box from '@mui/material/Box';

export default function Header() {
  return (
    <Box
      component="img"
      sx={{
        height: {
          xs: 220,
          sm: 280,
          md: 320,
          lg: 340,
          xl: 380
        },
        width: '100%',
      }}
      src={require('../assets/title.gif')}
    />
  )
}